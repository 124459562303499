// components/TaskManager.js

import React, { useEffect, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import Sidebar from '../../components/dashboard/Sidebar'
import Header from '../../components/dashboard/Header'
import Navigation from '../../components/dashboard/Navigation'
import MainView from '../../components/dashboard/MainView'
import GanttChart from '../../components/dashboard/GanttChart'
import EmpresaView from '../../components/dashboard/EmpresaView'
import RecordatoriosView from 'src/components/dashboard/Recordatorios'
import './TaskManager.css'
import { get, set } from 'lodash'
import Swal from 'sweetalert2'

const generateId = () => Math.random().toString(36).substr(2, 9)
const url = process.env.REACT_APP_BASE_URL;

const TaskManager = () => {
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const [boards, setBoards] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectableEmployees, setSelectableEmployees] = useState([]);
  const [registers, setRegisters] = useState([
    {
      id: 'reg1',
      task: 'Register Task 1',
      status: 'Done',
      person: 'Alice',
      startDate: '2023-06-04',
      endDate: '2023-06-08',
      priority: 'Medio'
    },
    {
      id: 'reg2',
      task: 'Register Task 2',
      status: 'Pending',
      person: 'Charlie',
      startDate: '2023-06-05',
      endDate: '2023-06-05',
      priority: 'Bajo'
    },
  ]);
  const [newRegisterTask, setNewRegisterTask] = useState('');
  const [openStatusDropdown, setOpenStatusDropdown] = useState(null);
  const [openPriorityDropdown, setOpenPriorityDropdown] = useState(null);
  const [openReminderPriorityDropdown, setOpenReminderPriorityDropdown] = useState(null);
  const [view, setView] = useState('main') // 'main', 'gantt', or 'empresa'
  const [currentDate, setCurrentDate] = useState(new Date())
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [selectedBoard, setSelectedBoard] = useState(null)
  const [jwt, setJwt] = useState("");
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEmployeesDropdown, setOpenEmployeesDropdown] = useState(null);
  const JWT = localStorage.getItem('jwt');
  if (!JWT) {
    window.location.href = '/login';
  }
  const data = JWT.split('.')[1];
  const user = JSON.parse(atob(data));
  const User_id = user.User_id;
  const Empresa_id = user.Empresa_id;

  const getTasks = async (id) => {
    await fetch(`${url}/tareas/${id}`, {
      method: 'GET',
      headers: {
        'auth': JWT,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setTasks(data["data"].filter(task => task.Proceso_id === null));
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  }

  const getBoards = async () => {
    await fetch(`${url}/tableros/usuario/${User_id}`, {
      method: 'GET',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        
        setBoards(data["data"]);
        return data["data"];
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  const onDragStart = (start) => {
    
    const type = start.source.droppableId === 'registers' ? 'register' : 'task'
  }



  const onDragEnd = async (result) => {
    
    const { source, destination, type } = result;
    

    


    if (!destination) return

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    if (type === 'board') {
      const newBoards = Array.from(boards)
      const [reorderedBoard] = newBoards.splice(source.index, 1)
      newBoards.splice(destination.index, 0, reorderedBoard)
      setBoards(newBoards)
      return
    }

    if (type === 'process') {
      const boardIndex = boards.findIndex(board => board.id === selectedBoard);
      const configuracion = JSON.parse(boards[boardIndex]["configuracion"]);

      const newOrder = Array.from(configuracion["orden"]);
      const [movedItem] = newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, movedItem);
      configuracion["orden"] = newOrder;

      await fetch(`${url}/tableros/put/configuracion/${selectedBoard}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          configuracion: {
            orden: newOrder
          }
        })
      })
        .then(response => response.json())
        .then(data => data);

      await getBoards();

      const newProcesses = Array.from(boards[boardIndex].processes);
      const [reorderedProcess] = newProcesses.splice(source.index, 1);
      newProcesses.splice(destination.index, 0, reorderedProcess);

      const newBoards = [...boards];
      newBoards[boardIndex] = {
        ...newBoards[boardIndex],
        processes: newProcesses,
        configuracion: JSON.stringify(configuracion)
      };

      setBoards(newBoards);
      return;
    }

    if (source.droppableId === 'registers') {
      const sourceRegisters = Array.from(registers);
      const [movedRegister] = sourceRegisters.splice(source.index, 1);
      const processId = destination.droppableId;
      const taskIndex = movedRegister.id;
      const boardIndex = boards.findIndex(board => board.id === selectedBoard);
      const processIndex = boards[boardIndex].processes.findIndex(process => process.id === processId);
      const configuracion = JSON.parse(boards[boardIndex].processes[processIndex]["configuracion"]);

      await fetch(`${url}/issues/put/proceso/${movedRegister.id}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Proceso_id: processId
        })
      })
        .then(response => response.json())
        .then(data => data);

      await fetch(`${url}/procesos/put/configuracion/${processId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          configuracion: {
            orden: Object.keys(configuracion).length === 0 ? [`A${taskIndex}`] : [...configuracion["orden"], `A${taskIndex}`]
          }
        })
      })
        .then(response => response.json())
        .then(data => data);
      setRegisters(sourceRegisters.filter(register => register.id !== movedRegister.id));
      await getBoards();
    } else if (source.droppableId === 'tasks') {
      const sourceTasks = Array.from(tasks);
      const [movedTask] = sourceTasks.splice(source.index, 1);
      const processId = destination.droppableId;
      const taskIndex = movedTask.id;
      const boardIndex = boards.findIndex(board => board.id === selectedBoard);
      const processIndex = boards[boardIndex].processes.findIndex(process => process.id === processId);
      const configuracion = JSON.parse(boards[boardIndex].processes[processIndex]["configuracion"]);

      await fetch(`${url}/tareas/put/proceso/${movedTask.id}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Proceso_id: processId
        })
      })
        .then(response => response.json())
        .then(data => data);

      await fetch(`${url}/procesos/put/configuracion/${processId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          configuracion: {
            orden: Object.keys(configuracion).length === 0 ? [taskIndex] : [...configuracion["orden"], taskIndex]
          }
        })
      })
        .then(response => response.json())
        .then(data => data);
      setTasks(sourceTasks.filter(task => task.id !== movedTask.id));
      await getBoards();

    }
    else {
      /* TODO: REVISAR ISSUES */
      const boardIndex = boards.findIndex(board => board.id === selectedBoard);
      boards[boardIndex].processes.map(async process => {
        if (process.id === source.droppableId) {
          if (process.id === destination.droppableId) {
            const configuracion = JSON.parse(process["configuracion"]);
            const newOrder = Array.from(configuracion["orden"]);
            const [movedItem] = newOrder.splice(source.index, 1);
            newOrder.splice(destination.index, 0, movedItem);
            configuracion["orden"] = newOrder;
            await fetch(`${url}/procesos/put/configuracion/${process.id}`, {
              method: 'PUT',
              headers: {
                'auth': jwt,
                'mode': 'no-cors',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                configuracion: {
                  orden: newOrder
                }
              })
            })
              .then(response => response.json())
              .then(data => data);

            await getBoards();
          }
        }
        if (process.id === destination.droppableId && process.id !== source.droppableId) {
          const sourceProcess = boards[boardIndex].processes.find(p => p.id === source.droppableId);
          const movedTask = sourceProcess.tasks[source.index];
          const sourceConfiguration = JSON.parse(sourceProcess["configuracion"]);
          const destinationConfiguration = JSON.parse(process["configuracion"]);
          const movedTaskId = movedTask.detalle ? `A${movedTask.id}` : movedTask.id;
          const newSourceConfiguration = sourceConfiguration["orden"].filter(item => item !== movedTaskId);
          const newDestinationConfiguration = [...destinationConfiguration["orden"]];
          newDestinationConfiguration.splice(destination.index, 0, movedTaskId);


          await fetch((movedTask.detalle ? `${url}/issues/put/proceso/${movedTask.id}` : `${url}/tareas/put/proceso/${movedTask.id}`), {
            method: 'PUT',
            headers: { 'auth': jwt, 'mode': 'no-cors', 'Content-Type': 'application/json' },
            body: JSON.stringify({ Proceso_id: destination.droppableId })
          })
            .then(response => response.json())
            .then(data => data);
          await fetch(`${url}/procesos/put/configuracion/${sourceProcess.id}`, {
            method: 'PUT',
            headers: { 'auth': jwt, 'mode': 'no-cors', 'Content-Type': 'application/json' },
            body: JSON.stringify({ configuracion: { orden: newSourceConfiguration } })
          })
            .then(response => response.json())
            .then(data => data);
          await fetch(`${url}/procesos/put/configuracion/${process.id}`, {
            method: 'PUT',
            headers: { 'auth': jwt, 'mode': 'no-cors', 'Content-Type': 'application/json' },
            body: JSON.stringify({ configuracion: { orden: newDestinationConfiguration } })
          })
            .then(response => response.json())
            .then(data => data);
          await getBoards();
        }
      });
    }
   }

  const addNewTask = async (processId) => {
    let taskIndex;
    const boardIndex = boards.findIndex(board => board.id === selectedBoard);
    const processIndex = boards[boardIndex].processes.findIndex(process => process.id === processId);
    const configuracion = JSON.parse(boards[boardIndex].processes[processIndex]["configuracion"]);

    if (boards[boardIndex].processes[processIndex].newTaskContent?.trim()) {
      const hourOffset = parseInt(localStorage.getItem('offset'), 10);
      const currentDate = new Date();
      console.log("currentDate", currentDate);
      const adjustedDate = new Date(currentDate.getTime() + hourOffset * 60 * 60 * 1000);
      console.log("adjustedDate", adjustedDate);
      const formattedDate = adjustedDate.toISOString().split('T')[0];
      const dbTask = {
        Empresa_id: Empresa_id,
        User_id: null,
        Proceso_id: processId,
        titulo: boards[boardIndex].processes[processIndex].newTaskContent,
        tipo_tarea: '',
        estado: 0, /* Pending */
        prioridad: 1, /* Bajo */
        fecha: formattedDate
      }

      await fetch(`${url}/tareas/post/monday`, {
        method: 'POST',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dbTask)
      })
        .then(response => response.json())
        .then(data => {
          taskIndex = data["id"];
        });

      await fetch(`${url}/procesos/put/configuracion/${processId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          configuracion: {
            orden: Object.keys(configuracion).length === 0 ? [taskIndex] : [...configuracion["orden"], taskIndex]
          }
        })
      })
        .then(response => response.json())
        .then(data => data);

      await getBoards();
    }
  }

  const updateNewTaskContent = (processId, content) => {
    setBoards(prevBoards => {
      const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
      const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
      const newProcesses = [...prevBoards[boardIndex].processes]
      newProcesses[processIndex] = { ...newProcesses[processIndex], newTaskContent: content }
      const newBoards = [...prevBoards]
      newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
      return newBoards
    })
  }

  const updateTaskTitle = (processId, taskId, newTitle, taskType) => {
    const putMode = 'Titulo';
    const putValue = newTitle;
    

    const endpoint = taskType === 'Tarea' ? 'tareas' : 'issues';
    if (taskType === 'Registro') {
      fetch(`${url}/${endpoint}/update/${taskId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          putMode,
          putValue
        })
      })
        .then(response => response.json())
        .then(data => data)
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });
    } else {
      fetch(`${url}/${endpoint}/put/monday/${taskId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          putMode,
          putValue
        })
      })
        .then(response => response.json())
        .then(data => data)
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });
    }
    getBoards();
  };

  const toggleEditMode = (processId) => {
    setBoards(prevBoards => {
      const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
      const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
      const newProcesses = [...prevBoards[boardIndex].processes]
      newProcesses[processIndex] = { ...newProcesses[processIndex], isEditing: !newProcesses[processIndex].isEditing }
      const newBoards = [...prevBoards]
      newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
      return newBoards
    })
  }

  const updateProcessTitle = (processId, newTitle) => {
    setBoards(prevBoards => {
      const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
      const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
      const newProcesses = [...prevBoards[boardIndex].processes]
      newProcesses[processIndex] = { ...newProcesses[processIndex], title: newTitle }
      const newBoards = [...prevBoards]
      newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
      return newBoards
    })
  }

  const updateBoardTitle = (boardId, newTitle) => {
    setBoards(prevBoards =>
      prevBoards.map(board =>
        board.id === boardId ? { ...board, title: newTitle } : board
      )
    );
  };

  const deleteBoard = (boardId) => {
    //setBoards(prevBoards => prevBoards.filter(board => board.id !== boardId))
    
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡Todos los procesos y tareas asociados a este tablero serán eliminados!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (boards.length === 1) {
          Swal.fire({
            title: '¡No puedes eliminar el último tablero!',
            icon: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar'
          })
        } else {
          await fetch(`${url}/tableros/delete/${boardId}`, {
            method: 'DELETE',
            headers: {
              'auth': jwt,
              'mode': 'no-cors',
              'Content-Type': 'application/json',
            }
          })
            .then(response => response.json())
            .then(data => console.log(data));

          await getBoards();
          setSelectedBoard(boards[0].id);

        }
      }
    }
    );
  }

  const finishEditingProcessTitle = (processId) => {
    setBoards(prevBoards => {
      const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
      const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
      const newProcesses = [...prevBoards[boardIndex].processes]
      newProcesses[processIndex] = { ...newProcesses[processIndex], isEditing: false }
      const newTitle = newProcesses[processIndex]["title"];
      const newBoards = [...prevBoards]
      newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
      fetch(`${url}/procesos/put/titulo/${processId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ titulo: newTitle })
      })
        .then(response => response.json())
        .then(data => console.log(data));
      return newBoards
    })
  }

  const addBoard = async () => {
    /* setBoards([...boards, { id: generateId(), title: 'New Board', processes: [] }]); */
    const dbBoard = {
      User_id: User_id,
      titulo: 'Nuevo tablero',
      configuracion: {}
    }
    await fetch(`${url}/tableros/post`, {
      method: 'POST',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dbBoard)
    })
      .then(response => response.json())
      .then(data => console.log(data));

    await getBoards();
  }

  const handleKeyDown = (e, processId) => {
    if (e.key === 'Enter') {
      finishEditingProcessTitle(processId)
    }
  }

  const addNewProcess = async () => {
    let newProcessId;
    console.log(selectedBoard);

    const currentBoard = boards.find(board => board.id === selectedBoard);
    console.log(currentBoard);

    const configuracion = JSON.parse(currentBoard["configuracion"]);

    const dbProcess = {
      Tablero_id: selectedBoard,
      titulo: "Nuevo proceso",
      configuracion: { orden: [] },
      tipo: "1"
    }

    await fetch(`${url}/procesos/post`, {
      method: 'POST',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dbProcess)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        newProcessId = data["id"]
      });

    await fetch(`${url}/tableros/put/configuracion/${selectedBoard}`, {
      method: 'PUT',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        configuracion: {
          orden: Object.keys(configuracion).length === 0 ? [newProcessId] : [...configuracion["orden"], newProcessId]
        }
      })
    })
      .then(response => response.json())
      .then(data => console.log(data));

    getBoards();
  }

  const addNewRegister = () => {
    if (!newRegisterTask.trim()) return
    const today = new Date().toISOString().split('T')[0]
    const newRegister = {
      id: generateId(),
      task: newRegisterTask,
      status: 'Pending',
      person: '',
      startDate: today,
      endDate: today,
      priority: 'Bajo'
    }
    setRegisters([...registers, newRegister])
    setNewRegisterTask('')
  }

  const deleteProcess = async (processId) => {
    const currentBoard = boards.find(board => board.id === selectedBoard);
    const configuracion = JSON.parse(currentBoard["configuracion"]);
    const newOrder = configuracion["orden"].filter(item => item !== processId);
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡Todos los procesos y tareas asociados a este proceso serán eliminados!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {

        console.log("newOrder", newOrder);

        await fetch(`${url}/procesos/delete/${processId}`, {
          method: 'DELETE',
          headers: {
            'auth': jwt,
            'mode': 'no-cors',
            'Content-Type': 'application/json',
          }
        })
          .then(response => response.json())
          .then(data => console.log(data));

        await fetch(`${url}/tableros/put/configuracion/${selectedBoard}`, {
          method: 'PUT',
          headers: {
            'auth': jwt,
            'mode': 'no-cors',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            configuracion: {
              orden: newOrder
            }
          })
        })
          .then(response => response.json())
          .then(data => console.log(data));

        await getBoards();
      }
    });
  }

  const toggleStatusDropdown = (task) => {
    if (task === "close") { setOpenStatusDropdown(null); return; }
    const id = task.detalle ? `Registro-${task.id}` : `Tarea-${task.id}`;
    setOpenStatusDropdown(openStatusDropdown === id ? null : id);
    setOpenPriorityDropdown(null);
    setOpenReminderPriorityDropdown(null);
    setOpenEmployeesDropdown(null);
  };

  const togglePriorityDropdown = (task) => {
    if (task === "close") { setOpenPriorityDropdown(null); return; }
    const id = task.detalle ? `Registro-${task.id}` : `Tarea-${task.id}`;
    setOpenPriorityDropdown(openPriorityDropdown === id ? null : id);
    setOpenReminderPriorityDropdown(null);
    setOpenStatusDropdown(null);
    setOpenEmployeesDropdown(null);
  };

  const toggleReminderPriorityDropdown = (reminder) => {
    const id = `Recordatorio-${reminder.id}`;
    setOpenReminderPriorityDropdown(openReminderPriorityDropdown === id ? null : id);
    setOpenPriorityDropdown(null);
    setOpenStatusDropdown(null);
    setOpenEmployeesDropdown(null);
  };

  const toggleEmployeesDropdown = (task) => {
    if (task === "close") { setOpenEmployeesDropdown(null); return; }
    const id = task.detalle ? `Registro-${task.id}` : `Tarea-${task.id}`;
    setOpenEmployeesDropdown(openEmployeesDropdown === id ? null : id);
    setOpenStatusDropdown(null);
    setOpenPriorityDropdown(null);
    setOpenReminderPriorityDropdown(null);
  };

  const updateTaskEmployee = (processId, taskId, newEmployee) => {
    const putMode = 'USER';
    const putValue = newEmployee;

    fetch(`${url}/tareas/put/monday/${taskId}`, {
      method: 'PUT',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        putMode,
        putValue
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
    getBoards();
    setOpenEmployeesDropdown(null)
  };

  const updateTaskStatus = (processId, taskId, newStatus, taskType) => {
    console.log(processId, taskId, newStatus, taskType);
    let status;
    if (taskType === 'Tarea') {
      const putMode = 'STATUS';
      /* const putValue = (newStatus === 'Pending') ? 0 : 2; */
      switch (newStatus) {
        case "Pendiente":
          status = 0;
          break;
        case "En progreso":
          status = 1;
          break;
        case "Hecho":
          status = 2;
          break;
        default:
          break;
      }
      fetch(`${url}/tareas/put/monday/${taskId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          putMode,
          putValue: status
        })
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });
      getBoards();
      setOpenStatusDropdown(null)
    }
    else if (taskType === 'Registro') {
      const putMode = 'DONE';
      /* const putValue = (newStatus === 'Pending') ? 0 : (newStatus === 'Done') ? 2 : 1 ; */
      switch (newStatus) {
        case "Pendiente":
          status = 0;
          break;
        case "En progreso":
          status = 1;
          break;
        case "Hecho":
          status = 2;
          break;
        default:
          break;
      }
      fetch(`${url}/issues/update/${taskId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          putMode,
          putValue: status
        })
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });
      getBoards();
      setOpenStatusDropdown(null)
    }


  };

  const updateTaskPriority = (processId, taskId, newPriority, taskType) => {
    if (taskType === 'Tarea') {
      const putMode = 'PRIORITY';
      let putValue;

      if (newPriority === 'Bajo') {
        putValue = 1;
      } else if (newPriority === 'Medio') {
        putValue = 2;
      } else if (newPriority === 'Alto') {
        putValue = 3;
      }

      fetch(`${url}/tareas/put/monday/${taskId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          putMode,
          putValue
        })
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });

      getBoards();
      setOpenPriorityDropdown(null)
    }
    else if (taskType === 'Registro') {
      const putMode = 'PRIORIDAD';
      let putValue;

      if (newPriority === 'Bajo') {
        putValue = 1;
      } else if (newPriority === 'Medio') {
        putValue = 2;
      } else if (newPriority === 'Alto') {
        putValue = 3;
      }


      fetch(`${url}/issues/update/${taskId}`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          putMode,
          putValue
        })
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });

      getBoards();
      setOpenPriorityDropdown(null)
    }
  };

  function setupWebSocket(info) {
    const uri = 'wss://websocket-eqjlrpwjoa-uc.a.run.app';
    let connection = new WebSocket(uri);
    let pingInterval;

    const sendPing = () => {
      if (connection.readyState === WebSocket.OPEN) {
        connection.send('ping');
      }
    };

    const startPing = () => {
      pingInterval = setInterval(sendPing, 30000); // Envía un ping cada 30 segundos
    };

    const stopPing = () => {
      clearInterval(pingInterval);
    };

    connection.addEventListener('open', () => {
      startPing();
    });

    connection.addEventListener('message', (event) => {
      const data = event.data;

      if (data === 'pong') {
        
        return; // No hacer nada más si es un pong
      }



      // Verificar que data es una cadena y contiene el carácter ':'
      if (typeof data === 'string' && data.includes(':')) {
        const [type, refreshed_id] = data.split(':').map(item => item.trim());

        try {
          if (type === 'issues' && parseInt(info.Empresa_id) === parseInt(refreshed_id)) {
            setLoading(true);
            fetch(`${url}/issues/empresa/${Empresa_id}`, {
              method: 'GET',
              headers: {
                'auth': jwt,
                'mode': 'no-cors',
                'Content-Type': 'application/json',
              },
            })
              .then(response => response.json())
              .then(data => {
                //TODO: REMOVE REGISTROS FROM BACKEND
                const filteredRegister = data.filter((register) => register.Proceso_id === null);
                
                setRegisters(filteredRegister);
                setLoading(false);
              })
              .catch(error => {
                console.error('Error al obtener los datos:', error);
              });
          } else if (type === 'task' && parseInt(info.Empresa_id) === parseInt(refreshed_id)) {
            setLoading(true);
            getTasks(info.Empresa_id);
            getBoards();
            setLoading(false);
          }
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      } 
    });

    connection.addEventListener('close', () => {
      
      stopPing();
      setTimeout(() => setupWebSocket(info), 1000); // Intenta reconectar después de 1 segundo
    });

    connection.addEventListener('error', (error) => {
      console.error('WebSocket error:', error);
      connection.close(); // Cierra la conexión en caso de error para intentar reconectar
    });
  };

  function setupJwt() {
    //localStorage.setItem('jwt', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im9ubnlvdG9AZ21haWwuY29tIiwiVXNlcl9pZCI6MzEwLCJFbXByZXNhX2lkIjoxNjIsImlhdCI6MTcyODA1NjY3OCwiZXhwIjoxNzI4MDYwMjc4fQ.hREjvIMQxJOhAVMklixZBtZygz3JhPkBCVjt68arJtA");
    const jwt = localStorage.getItem('jwt');
    setJwt(jwt);
    const decoded = Buffer.from(jwt.split('.')[1], 'base64').toString('ascii');
    const data = JSON.parse(decoded);
    
    const response = {
      "jwt": jwt,
      "Empresa_id": data.Empresa_id
    }
    return response;
  };

  function setupOffset() {
    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();
    const offsetHours = offsetMinutes / 60;
    const offsetDirection = offsetHours > 0 ? '-' : '+';
    const offsetFormatted = `${offsetDirection}${Math.abs(offsetHours)}`;
    localStorage.setItem("offset", parseInt(offsetFormatted));
  };

  useEffect(() => {
    const info = setupJwt();
    if (info.Empresa_id == 162) {
      window.location.href = "/tasks/tuniche";
    }
    else if (info.Empresa_id == 117 || info.Empresa_id == 166) {
      window.location.href = "/tasks/iansa";
    }
    setupOffset();
    setupWebSocket(info);
    getTasks(info.Empresa_id);
    fetch(`${url}/tableros/usuario/${User_id}`, {
      method: 'GET',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setBoards(data["data"]);
        setLoading(false);
        setSelectedBoard(data["data"][0]["id"]);
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
    fetch(`${url}/usuarios/empresa/${User_id}`, {
      method: 'GET',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setEmployees(data["usuarios"]);
        setSelectableEmployees(data["usuarios"]);
        
      })

  }, []);

  useEffect(() => {
    fetch(`${url}/issues/empresa/${Empresa_id}`, {
      method: 'GET',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        //TODO: REMOVE REGISTROS FROM BACKEND
        const filteredRegister = data.filter((register) => register.Proceso_id === null);
        
        setRegisters(filteredRegister);
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex h-screen bg-gray-100 justify-center items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar
        boards={boards}
        selectedBoard={selectedBoard}
        setSelectedBoard={setSelectedBoard}
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        addBoard={addBoard}
      />
      <div className="flex-1 overflow-auto">
        <div className="p-4">
          <Header
            selectedBoard={selectedBoard}
            boards={boards}
            updateBoardTitle={updateBoardTitle}
            deleteBoard={deleteBoard}
          />
          <Navigation
            view={view}
            setView={setView}
          />
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            {view === 'main' && (
              <MainView
                boards={boards}
                selectedBoard={selectedBoard}
                registers={registers}
                setRegisters={setRegisters}
                tasks={tasks}
                setTasks={setTasks}
                addNewProcess={addNewProcess}
                addNewRegister={addNewRegister}
                newRegisterTask={newRegisterTask}
                setNewRegisterTask={setNewRegisterTask}
                toggleStatusDropdown={toggleStatusDropdown}
                togglePriorityDropdown={togglePriorityDropdown}
                toggleEmployeesDropdown={toggleEmployeesDropdown}
                updateTaskStatus={updateTaskStatus}
                updateTaskPriority={updateTaskPriority}
                updateTaskEmployee={updateTaskEmployee}
                openStatusDropdown={openStatusDropdown}
                openPriorityDropdown={openPriorityDropdown}
                openEmployeesDropdown={openEmployeesDropdown}
                toggleEditMode={toggleEditMode}
                updateProcessTitle={updateProcessTitle}
                finishEditingProcessTitle={finishEditingProcessTitle}
                handleKeyDown={handleKeyDown}
                employees={employees}
                deleteProcess={deleteProcess}
                addNewTask={addNewTask}
                updateNewTaskContent={updateNewTaskContent}
                getBoards={getBoards}
                updateTaskTitle={updateTaskTitle}
              />
            )}
            {view === 'gantt' && (
              <GanttChart
                boards={boards}
                registers={registers}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                selectedBoard={selectedBoard}
              />
            )}
            {view === 'empresa' && <EmpresaView />}
            {view === 'recordatorios' && (
              <RecordatoriosView
                assignedEmployees={assignedEmployees}
                employees={employees}
                Empresa_id={Empresa_id}
                jwt={jwt}
                offset={parseInt(localStorage.getItem('offset'))}
                openReminderPriorityDropdown={openReminderPriorityDropdown}
                selectableEmployees={selectableEmployees}
                setAssignedEmployees={setAssignedEmployees}
                setSelectableEmployees={setSelectableEmployees}
                setOpenReminderPriorityDropdown={setOpenReminderPriorityDropdown}
                toggleReminderPriorityDropdown={toggleReminderPriorityDropdown}
              />
            )}
          </DragDropContext>
        </div>
      </div>
    </div>
  )
}

export default TaskManager