import React, { useState, useEffect } from 'react'
import { Menu, X, Plus } from 'lucide-react'

const Sidebar = ({ boards, selectedBoard, setSelectedBoard, sidebarOpen, toggleSidebar, addBoard }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth < 768)
    checkIfMobile()
    window.addEventListener('resize', checkIfMobile)
    return () => window.removeEventListener('resize', checkIfMobile)
  }, [])

  return (
    <>
      {isMobile && (
        <button 
          onClick={toggleSidebar} 
          className="fixed top-4 left-4 z-50 p-2 bg-white text-black rounded-md"
          aria-label={sidebarOpen ? "Close sidebar" : "Open sidebar"}
        >
          <Menu />
        </button>
      )}

      <div 
        className={`${
          isMobile 
            ? `fixed top-0 left-0 h-full z-40 ${sidebarOpen ? 'w-full bg-white' : 'w-0 bg-transparent'}`
            : `bg-white text-black ${sidebarOpen ? 'w-52' : 'w-16'}`
        } transition-all duration-300 ease-in-out overflow-hidden ${sidebarOpen ? 'overflow-y-auto h-full' : ''}`}
      >
        <div className="p-4 flex justify-between items-center">
          <h2 className={`font-bold ml-12 md:ml-0 ${sidebarOpen ? '' : 'hidden'}`}>Tableros</h2>
          {!isMobile && (
            <button onClick={toggleSidebar} className="text-black">
              {sidebarOpen ? <X /> : <Menu />}
            </button>
          )}
        </div>
        <nav>
          {boards.map(board => (
            <button
              key={board.id}
              onClick={() => {
                setSelectedBoard(board.id)
                if (isMobile) toggleSidebar()
              }}
              className={`w-full text-left p-4 hover:bg-gray-200 ${selectedBoard === board.id ? 'bg-gray-100' : ''}`}
            >
              {sidebarOpen ? board.title : `${board.title[0]}`}
            </button>
          ))}
        </nav>
        {sidebarOpen && (
          <div className="p-2">
            <button 
              onClick={addBoard} 
              className="flex items-center justify-center w-full text-left p-2 hover:bg-gray-200 bg-gray-100 rounded-md"
            >
              <Plus className="w-4 h-4" />
              {isMobile && <span className="ml-2">Nuevo tablero</span>}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default Sidebar