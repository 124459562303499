import React, { createContext, useContext, useState } from 'react';

const TabsContext = createContext();

export function Tabs({ children, defaultValue, onValueChange, className }) {
  const [value, setValue] = useState(defaultValue);

  const handleValueChange = (newValue) => {
    setValue(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return (
    <TabsContext.Provider value={{ value, onChange: handleValueChange }}>
      <div className={className}>{children}</div>
    </TabsContext.Provider>
  );
}

export function TabsList({ children, className }) {
  return <div className={`flex space-x-1 rounded-xl bg-white-900/20 p-1 ${className}`}>{children}</div>;
}

export function TabsTrigger({ children, value }) {
  const { value: activeValue, onChange } = useContext(TabsContext);
  const isActive = activeValue === value;

  return (
    <button
      className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white-700 ring-gray-300 ring-opacity-60 ring-offset-2 ring-offset-gray-400 focus:outline-none focus:ring-2 ${
        isActive
          ? 'bg-green-400 shadow text-white-900'
          : 'bg-gray-200 text-white-100 hover:bg-gray-300 hover:text-black'
      }`}
      onClick={() => onChange(value)}
    >
      {children}
    </button>
  );
}

export function TabsContent({ children, value }) {
  const { value: activeValue } = useContext(TabsContext);

  if (activeValue !== value) return null;

  return <div className="rounded-xl bg-white p-3 ring-1 ring-blue-900/5 shadow-xl">{children}</div>;
}