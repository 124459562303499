import React, { useEffect, useState } from 'react';
import { PencilIcon, TrashIcon, PlusIcon } from 'lucide-react';
import Swal from 'sweetalert2';

const url = process.env.REACT_APP_BASE_URL;
const urlWhatsapp = process.env.REACT_APP_WHATSAPP_URL;

const generateId = () => Math.random().toString(36).substring(2, 11);

export default function EmpresaView() {
  const [workers, setWorkers] = useState([]);
  const [editingWorker, setEditingWorker] = useState(null);
  const [newWorker, setNewWorker] = useState({ nombre: '', telefono: '', rol: '', email: '' });
  const [isAddingWorker, setIsAddingWorker] = useState(false);
  const [jwt, setJwt] = useState("");

  const JWT = localStorage.getItem('jwt');
  const data = JWT.split('.')[1];
  const user = JSON.parse(atob(data));
  const User_id = user.User_id;
  const Empresa_id = user.Empresa_id;

  const getUsers = async () => {
    fetch(`${url}/usuarios/empresa/${User_id}`, {
      method: 'GET',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {

        setWorkers(data["usuarios"]);
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  };

  const handleEdit = (worker) => {
    setEditingWorker({
      id: worker.id,
      nombre: worker.name,
      telefono: worker.phone,
      rol: worker.employeeType,
      email: worker.email
    });
    setIsAddingWorker(false);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, borrarlo!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${url}/usuarios/delete/${id}`, {
          method: 'DELETE',
          headers: {
            'auth': jwt,
            'mode': 'no-cors',
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            getUsers();
          })
          .catch(error => {
            console.error('Error al obtener los datos:', error);
          });
      }
    });
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (editingWorker) {
      fetch(`${url}/usuarios/update`, {
        method: 'PUT',
        headers: {
          'auth': jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "id": editingWorker.id,
          "name": editingWorker.nombre,
          "phone": editingWorker.telefono,
          "employeeType": editingWorker.rol,
          "email": editingWorker.email
        })
      })
        .then(response => response.json())
        .then(data => {
          getUsers();
        })
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });

      setEditingWorker(null);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditingWorker((prevWorker) => ({
      ...prevWorker,
      [name]: value
    }));
  };

  const handleNewWorkerInputChange = (event) => {
    const { name, value } = event.target;
    setNewWorker((prevWorker) => ({
      ...prevWorker,
      [name]: value
    }));
  };

  const handleAddWorker = (event) => {
    event.preventDefault();
    const id = generateId();
    fetch(`${url}/usuarios/post`, {
      method: 'POST',
      headers: {
        'auth': jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "empresa_id": Empresa_id,
        "name": newWorker.nombre,
        "phone": newWorker.telefono,
        "employeeType": newWorker.rol,
        "email": newWorker.email
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.message === "El correo ya existe") {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'El correo ya existe',
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Trabajador agregado',
            showConfirmButton: false,
          });
          setWorkers([...workers, { id, ...newWorker }]);
          setNewWorker({ nombre: '', telefono: '', rol: '', email: '' });
        }
        fetch(`${urlWhatsapp}/send-welcome-message`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Allow-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({
            "nombre": newWorker.nombre,
            "number": newWorker.telefono,
            "empresa": Empresa_id
          })

        })
          .then(response => response.json())
          .then(data => {
            console.log(data);
          })
          .catch(error => {
            console.error('Error:', error);
          });
        getUsers();
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });

    setIsAddingWorker(false);
  };

  function setupJwt() {
    const jwt = localStorage.getItem('jwt');
    setJwt(jwt);
    const decoded = Buffer.from(jwt.split('.')[1], 'base64').toString('ascii');
    const data = JSON.parse(decoded);
    const response = {
      "jwt": jwt,
      "Empresa_id": data.Empresa_id
    };
    return response;
  }

  useEffect(() => {
    setupJwt();
    getUsers();
  }, []);

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Trabajadores de la Empresa</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Teléfono</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rol</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {workers.map((worker) => (
              <tr key={worker.id}>
                <td className="px-6 py-4 whitespace-nowrap">{worker.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{worker.phone}</td>
                <td className="px-6 py-4 whitespace-nowrap">{worker.email}</td>
                <td className="px-6 py-4 whitespace-nowrap">{worker.employeeType}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => handleEdit(worker)}
                    className="text-black hover:text-gray-700 mr-2"
                    aria-label={`Editar ${worker.name}`}
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(worker.id)}
                    className="text-black hover:text-gray-700"
                    aria-label={`Borrar ${worker.name}`}
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {editingWorker && (
        <form onSubmit={handleSave} className="mt-8 space-y-4">
          <h3 className="text-lg font-medium">Editar Trabajador</h3>
          <div>
            <label htmlFor="nombre" className="block text-sm font-medium text-gray-700">Nombre</label>
            <input
              type="text"
              name="nombre"
              id="nombre"
              value={editingWorker.nombre}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="telefono" className="block text-sm font-medium text-gray-700">Teléfono</label>
            <input
              type="text"
              name="telefono"
              id="telefono"
              value={editingWorker.telefono}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={editingWorker.email}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="rol" className="block text-sm font-medium text-gray-700">Rol</label>
            <select
              name="rol"
              id="rol"
              value={editingWorker.rol}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="" disabled hidden>Rol</option>
              <option value="Administrador">Administrador</option>
              <option value="Supervisor">Supervisor</option>
              <option value="Operario">Operario</option>
            </select>
          </div>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Guardar Cambios
          </button>
        </form>
      )}

      {!isAddingWorker ? (
        <button
          onClick={() => setIsAddingWorker(true)}
          className="mt-4 inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <PlusIcon className="h-5 w-5 mr-1" />
          Agregar nuevo trabajador
        </button>
      ) : (
        <form onSubmit={handleAddWorker} className="mt-4 space-y-4">
          <h3 className="text-lg font-medium">Agregar Nuevo Trabajador</h3>
          <div className="flex space-x-4">
            <input
              type="text"
              name="nombre"
              placeholder="Nombre"
              value={newWorker.name}
              onChange={handleNewWorkerInputChange}
              className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
            <input
              type="text"
              name="telefono"
              placeholder="Teléfono"
              value={newWorker.phone}
              onChange={handleNewWorkerInputChange}
              className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={newWorker.email}
              onChange={handleNewWorkerInputChange}
              className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
            <select
              name="rol"
              value={newWorker.employeeType}
              onChange={handleNewWorkerInputChange}
              className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            >
              <option value="" disabled hidden selected>Rol</option>
              <option value="Administrador">Administrador</option>
              <option value="Supervisor">Supervisor</option>
              <option value="Operario">Operario</option>
            </select>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setIsAddingWorker(false)}
              className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Agregar
            </button>
          </div>
        </form>
      )}
    </div>
  );
}