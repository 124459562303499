import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import FormPage from './pages/FormPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import ComboPlusGooglemap from './pages/ComboPlusGooglemap';
import DashboardAppPage from './pages/AppWebsiteVisits';
import ProgamDeFertilizer from './pages/ProgamDeFertilizerCalculated';
import CuartelPage from './pages/CuartelPage';
import Modal1 from './pages/Modal1';
import Modal2 from './pages/Modal2';
import CalculationPage from './pages/CalculationPage';
import ProductCalculation from './pages/ProductCalculation';
import FundoPage from './pages/FundoPage';
import ChartPage from './pages/ChartPage';
import AplicationPage from './pages/AplicationPage';
import DemoPage from './pages/DemoPage';
import EnviosPage from './pages/EnviosPage';
import LoginPageYawi from './pages/LoginPageYawi';
import DrawMap from './pages/mapDrawer';
import LoginPageWhatsapp from './pages/LoginWhatsapp';
import RegisterFromWhatsapp from './pages/RegisterFromWhatsapp';
import LoginLCP from './pages/LoginLCP';
import EmpresaLCP from './pages/LCP/Empresa';
import FormularioLCP from './pages/LCP/Formulario';
import DashboardTaskLCP from './pages/LCP/DashboardTask';
import DespachosLCP from './pages/LCP/Despachos';
import CreateTaskLCP from './pages/LCP/CreateTask';
import DashboardLayoutLCP from './layouts/dashboardLCP/DashboardLayout';
import IngresosLCP from './pages/LCP/Ingresos';
import Monday from './pages/MondayDash';
import TaskManager from './pages/Dashboard/TaskManager';
import WefleetTable from './pages/Wefleet/MainTable';
import LoginWeFleet from './pages/LoginWefleet';
import AgileHealthBarometer from './pages/DIU';
import TaskManagerTuniche from './pages/Dashboard/TaskManagerTuniche';
import TaskManagerIansa from './pages/Dashboard/TaskManagerIansa';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/DIU',
      element: <AgileHealthBarometer />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/newDashboard',
      element: <TaskManager />,
    },
    {
      path: '/loginLCP',
      element: <LoginLCP />,
    },
    {
      path: 'login_yawi/:token',
      element: <LoginPageYawi />,
    },
    {
      path: '/envios',
      element: <EnviosPage />,
    },
    {
      path: '/demo',
      element: <DemoPage />,
    },
    {
      path: '/loginWhatsapp/:token',
      element: <LoginPageWhatsapp/>,
    },
    {
      path: '/registerFromWhatsapp',
      element: <RegisterFromWhatsapp />,
    },
    {
      path: '/loginWeFleet',
      element: <LoginWeFleet />,
    },
    {
      path: '/wefleet',
      element: <WefleetTable />,
    },
    {
      path: '/LCP',
      element: <DashboardLayoutLCP />, 
      children: [
        { element: <Navigate to="/LCP/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardTaskLCP /> },
        { path: 'despachos', element: <DespachosLCP /> },
        { path: 'ingresos', element: <IngresosLCP /> },
        { path: 'createTask', element: <CreateTaskLCP /> },
        { path: 'Empresa', element: <EmpresaLCP /> },
        { path: 'formularios', element: <FormularioLCP /> },
      ],
    },
    {
      path: '/tasks',
      children:
      [
        { element: <Navigate to="/tasks/dashboard" />, index: true },
        { path: 'dashboard', element: <TaskManager /> },
        { path: 'tuniche', element: <TaskManagerTuniche /> },
        { path: 'iansa', element: <TaskManagerIansa /> },
      ],
      // children: [
      //   { element: <Navigate to="/tasks/dashboard" />, index: true },
      //   { path: 'dashboard', element: <DashboardTask /> },
      //   { path: 'createTask', element: <CreateTask /> },
      //   { path: 'recordatorios', element: <Reminder /> },
      //   { path: 'issues', element: <Issues /> },
      //   /* { path: 'DemoPage/:empresa?', element: <DashboardTask />}, */
      //   { path: 'Empresa', element: <Empresa />},
      //   { path: 'formularios', element: <Formulario />},
      //   { path: 'monday', element: <Monday />},
      // ],
    },
    {
      path: '/dashboard',
      element: <TaskManager />,
    },
    /* {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'ComboPlusGooglemap', element: <ComboPlusGooglemap /> },
        { path: 'FormPage', element: <FormPage /> },
        { path: 'ProgamDeFertilizer', element: <ProgamDeFertilizer /> },
        { path: 'Modal1', element: <Modal1 /> },
        { path: 'Modal2', element: <Modal2 /> },
        { path: 'CalculationPage', element: <CalculationPage /> },
        { path: 'cuartel/:idCuartel', element: <CuartelPage /> },
        { path: 'ProgamDeFertilizer/:idCuartel/:idProductoN/:idProductoK/:idProductoP', element: <ProgamDeFertilizer /> },
        { path: 'ProductCalculation/sector/:idSector', element: <ProductCalculation /> },
        // TODO: redefinir nombres de rutas para que sean mas descriptivas
        { path: 'Fundos', element: <FundoPage /> },
        { path: 'ChartPage', element: <ChartPage /> },
        { path: 'Aplicación/:idCuartel?', element: <AplicationPage /> },
        { path: 'crearCuartel', element: <DrawMap /> },
      ],
    }, */
    {
      path: '*',
      element: <Navigate to="/tasks/dashboard" replace />,
    },

  ]);

  return routes;
}
