import React, { useState, useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Process from '../Tuniche/Process';
import Register from '../Tuniche/Register';
import { Plus } from 'lucide-react';
import { Tabs, TabsList, TabsTrigger } from '../dashboard/TabComponents';
import WhatsappTask from '../dashboard/WhatsappTask';

const url = process.env.REACT_APP_BASE_URL;

export default function MainViewTuniche({
  boards,
  selectedBoard,
  registers,
  setRegisters,
  tasks,
  setTasks,
  addNewProcess,
  addNewRegister,
  newRegisterTask,
  setNewRegisterTask,
  toggleStatusDropdown,
  togglePriorityDropdown,
  toggleEmployeesDropdown,
  updateTaskStatus,
  updateTaskPriority,
  updateTaskEmployee,
  openStatusDropdown,
  openPriorityDropdown,
  openEmployeesDropdown,
  toggleEditMode,
  updateProcessTitle,
  finishEditingProcessTitle,
  handleKeyDown,
  deleteProcess,
  addNewTask,
  updateNewTaskContent,
  employees,
  getBoards,
  updateTaskTitle
}) {
  const currentBoard = boards.find(board => board.id === selectedBoard);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("registros");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const registersPerPage = 5;
  const tasksPerPage = 5;

  useEffect(() => {
    // Ensure currentPage is within valid range after deleting items
    const totalRegistersPages = Math.max(1, Math.ceil(registers.length / registersPerPage));
    const totalTasksPages = Math.max(1, Math.ceil(tasks.length / tasksPerPage));

    if (currentPage > totalRegistersPages && activeTab === 'registros') {
      setCurrentPage(totalRegistersPages);
    }
    if (currentPage > totalTasksPages && activeTab === 'tareas') {
      setCurrentPage(totalTasksPages);
    }
  }, [registers, tasks, currentPage, activeTab]);

  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);
  const indexOfLastRegister = currentPage * registersPerPage;
  const indexOfFirstRegister = indexOfLastRegister - registersPerPage;
  const currentRegisters = registers.slice(indexOfFirstRegister, indexOfLastRegister);
  const totalRegistersPages = Math.max(1, Math.ceil(registers.length / registersPerPage));
  const totalTasksPages = Math.max(1, Math.ceil(tasks.length / tasksPerPage));

  const handleNextPage = () => {
    if (activeTab === 'registros' && currentPage < totalRegistersPages) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (!currentBoard) {
    console.warn("No se encontró el tablero seleccionado");
    return null;
  }

  const configuration = currentBoard["configuracion"] ? JSON.parse(currentBoard["configuracion"]) : {};
  const order = configuration["orden"] || [];

  const orderedProcesses = Array.isArray(currentBoard?.processes) && currentBoard.processes.length > 0
    ? currentBoard.processes.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
    : [];

  async function handleDeleteWhatsappTask(task) {
    try {
      const response = await fetch(`${url}/tareas/delete/${task.id}`, {
        method: 'DELETE',
        headers: {
          'mode': 'no-cors',
          'auth': localStorage.getItem('jwt'),
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const updatedTasks = tasks.filter((t) => t.id !== task.id);
      setTasks(updatedTasks);
      if (updatedTasks.length === 0 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
      getBoards();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  }

  async function handleDeleteRegister(register) {
    try {
      const response = await fetch(`${url}/issues/delete/${register.id}`, {
        method: 'DELETE',
        headers: {
          'mode': 'no-cors',
          'auth': localStorage.getItem('jwt'),
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const updatedRegisters = registers.filter((r) => r.id !== register.id);
      setRegisters(updatedRegisters);
      if (updatedRegisters.length === 0 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
      getBoards();
    } catch (error) {
      console.error('Error deleting register:', error);
    }
  }

  return (
    <div className="flex flex-col md:flex-row md:space-x-4">
      <div className="w-full md:w-3/4">
        <Droppable droppableId="processes" type="process">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
              {orderedProcesses.map((process, index) => (
                <Process
                  key={process.id}
                  type={process.tipo}
                  process={process}
                  index={index}
                  toggleStatusDropdown={toggleStatusDropdown}
                  togglePriorityDropdown={togglePriorityDropdown}
                  toggleEmployeesDropdown={toggleEmployeesDropdown}
                  updateTaskStatus={updateTaskStatus}
                  updateTaskPriority={updateTaskPriority}
                  updateTaskEmployee={updateTaskEmployee}
                  openStatusDropdown={openStatusDropdown}
                  openPriorityDropdown={openPriorityDropdown}
                  openEmployeesDropdown={openEmployeesDropdown}
                  toggleEditMode={toggleEditMode}
                  updateProcessTitle={updateProcessTitle}
                  finishEditingProcessTitle={finishEditingProcessTitle}
                  handleKeyDown={handleKeyDown}
                  deleteProcess={deleteProcess}
                  addNewTask={addNewTask}
                  updateNewTaskContent={updateNewTaskContent}
                  addNewProcess={addNewProcess}
                  employees={employees}
                  getBoards={getBoards}
                  updateTaskTitle={updateTaskTitle}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className="mt-4">
          <button
            onClick={addNewProcess}
            className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            aria-label="Add new process"
          >
            <Plus className="w-6 h-6" />
          </button>
        </div>
      </div>
      <div className="w-full md:w-1/4 mt-4 md:mt-0">
        <button
          className="md:hidden w-full bg-blue-500 text-white p-2 rounded mb-4"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? 'Ocular Registros/Tareas' : 'Mostrar Registros/Tareas'}
        </button>
        <div className={`bg-white rounded-lg shadow-md p-4 ${isMobileMenuOpen ? 'block' : 'hidden md:block'} sticky top-0`}>
          <Tabs defaultValue="registros" className="w-full max-w-md" onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-2 p-1 rounded-md bg-muted mb-2">
              <TabsTrigger value="registros">Registros</TabsTrigger>
              <TabsTrigger value="tareas">Tareas</TabsTrigger>
            </TabsList>
          </Tabs>
          {activeTab === 'registros' && (
            <>
              {currentRegisters.length === 0 ? (
                <p className="flex items-center justify-center h-full text-center">¡Sin nuevos registros!</p>
              ) : (
                <Droppable droppableId="registers" type="task">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                      {currentRegisters.map((register, index) => (
                        <Register
                          key={register.id}
                          register={register}
                          index={indexOfFirstRegister + index} // Índice global
                          handleDelete={handleDeleteRegister}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
            </>
          )}
          {activeTab === 'tareas' && (
            <>
              {currentTasks.length === 0 ? (
                <p className="flex items-center justify-center h-full text-center">¡Sin nuevas tareas!</p>
              ) : (
                <Droppable droppableId="tasks" type="task">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                      {currentTasks.map((task, index) => (
                        <WhatsappTask
                          key={task.id}
                          task={task}
                          index={indexOfFirstTask + index} // Índice global
                          handleDelete={handleDeleteWhatsappTask}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
            </>
          )}
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePrevPage}
              disabled={activeTab === 'registros' ? currentPage === 1 : currentPage === 1}
              className="px-4 py-2 bg-gray-300 text-base rounded disabled:opacity-50"
            >
              Anterior
            </button>
            <button
              onClick={handleNextPage}
              disabled={activeTab === 'registros' ? currentPage === totalRegistersPages : currentPage === totalTasksPages}
              className="px-4 py-2 bg-gray-300 text-base rounded disabled:opacity-50"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}