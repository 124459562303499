"use client"

import React from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { format, addDays, startOfMonth, endOfMonth, differenceInDays, isWithinInterval, max, min, parse, isValid } from 'date-fns'

const getInitials = (name) => {
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
}

const parseDate = (dateString) => {
  if (!dateString) return null

  // Primero, intenta parsear como fecha ISO
  const isoDate = new Date(dateString)
  if (isValid(isoDate)) {
    return isoDate
  }

  // Si no es una fecha ISO válida, intenta otros formatos
  const formats = [
    'yyyy-MM-dd',
    'dd/MM/yyyy',
    'MM/dd/yyyy',
    'yyyy-MM-dd HH:mm:ss',
    'dd/MM/yyyy HH:mm:ss',
  ]

  for (const formatString of formats) {
    const parsedDate = parse(dateString, formatString, new Date())
    if (isValid(parsedDate)) {
      return parsedDate
    }
  }

  console.warn(`No se pudo parsear la fecha: ${dateString}`)
  return null
}

const GanttChart = ({ currentDate, setCurrentDate, boards, selectedBoard }) => {
  const selectedBoardData = boards.find(board => board.id === selectedBoard)
  if (!selectedBoardData) return null

  const allTasks = selectedBoardData.processes.flatMap(process =>
    process.tasks.map(task => ({ ...task, boardName: selectedBoardData.title, processName: process.title }))
  )

  const startOfMonthDate = startOfMonth(currentDate)
  const endOfMonthDate = endOfMonth(currentDate)
  const daysInMonth = differenceInDays(endOfMonthDate, startOfMonthDate) + 1

  const filteredTasks = allTasks.filter(task => {
    const taskStart = parseDate(task.startDate)
    const taskEnd = parseDate(task.endDate)
    if (!taskStart) return false
    if (!taskEnd) {
      return isWithinInterval(taskStart, { start: startOfMonthDate, end: endOfMonthDate })
    }
    return (
      isWithinInterval(taskStart, { start: startOfMonthDate, end: endOfMonthDate }) ||
      isWithinInterval(taskEnd, { start: startOfMonthDate, end: endOfMonthDate }) ||
      (taskStart <= startOfMonthDate && taskEnd >= endOfMonthDate)
    )
  })

  const getMonthName = (date) => {
    return format(date, 'MMMM yyyy')
  }

  const goToPreviousMonth = () => {
    setCurrentDate(addDays(startOfMonthDate, -1))
  }

  const goToNextMonth = () => {
    setCurrentDate(addDays(endOfMonthDate, 1))
  }

  const getTaskPosition = (task) => {
    const taskStart = parseDate(task.startDate)
    const taskEnd = parseDate(task.endDate)
    if (!taskStart) return { start: 0, duration: 0 }

    const start = Math.max(0, differenceInDays(taskStart, startOfMonthDate))
    let duration
    if (!taskEnd) {
      duration = 1 // Solo muestra el día de inicio para tareas sin fecha de término
    } else {
      const end = Math.min(daysInMonth - 1, differenceInDays(taskEnd, startOfMonthDate))
      duration = end - start + 1
    }

    return { start, duration }
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <button onClick={goToPreviousMonth} className="p-2 rounded-full hover:bg-gray-200">
          <ChevronLeft className="w-6 h-6" />
        </button>
        <h2 className="text-xl font-bold">{getMonthName(currentDate)}</h2>
        <button onClick={goToNextMonth} className="p-2 rounded-full hover:bg-gray-200">
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="border p-2 bg-gray-100">Task</th>
              {Array.from({ length: daysInMonth }).map((_, index) => {
                const date = addDays(startOfMonthDate, index)
                return (
                  <th key={index} className={`border p-2 text-center ${date.getDay() === 0 || date.getDay() === 6 ? 'bg-gray-100' : ''}`}>
                    {format(date, 'd')}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {filteredTasks.map((task) => {
              const { start, duration } = getTaskPosition(task)
              return (
                <tr key={task.id} className="hover:bg-gray-50">
                  <td className="border p-2 font-medium">{task.task}</td>
                  {Array.from({ length: daysInMonth }).map((_, index) => {
                    if (index === start && duration > 0) {
                      return (
                        <td
                          key={index}
                          colSpan={duration}
                          className="border border-gray-200text-center relative"
                        >
                          <div
                            className={`absolute inset-0 flex items-center justify-center text-xs text-white ${
                              task.status === 'Done' ? 'bg-green-500' :
                              task.priority === 1 ? 'bg-red-500' :
                              task.priority === 2 ? 'bg-yellow-500' : 'bg-blue-500'
                            } group`}
                          >
                            <span className="z-10 truncate">{getInitials(task.person)}</span>
                            <div className="absolute invisible group-hover:visible bg-gray-800 text-white p-2 rounded shadow-lg -top-12 left-1/2 transform -translate-x-1/2 whitespace-nowrap z-50">
                              <div>{task.person}</div>
                              <div>{task.boardName}: {task.task}</div>
                              <div>Start: {task.startDate ? format(parseDate(task.startDate), 'MMM d, yyyy') : 'Invalid date'}</div>
                              <div>End: {task.endDate ? format(parseDate(task.endDate), 'MMM d, yyyy') : 'Not specified'}</div>
                              <div>Duration: {task.endDate ? `${duration} day(s)` : 'Ongoing'}</div>
                            </div>
                          </div>
                        </td>
                      )
                    } else if (index > start && index < start + duration) {
                      return null // These cells are covered by the colSpan
                    } else {
                      return <td key={index} className="border p-2"></td>
                    }
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default GanttChart